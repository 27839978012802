








import { Component, Vue } from "vue-property-decorator";
import Hero from "@/sections/Networks/Hero/Hero.vue";
import Explain from "@/sections/Networks/Explain/Explain.vue";
import Grid from "@/sections/Networks/Grid/Grid.vue";

@Component({
  components: {
    Hero,
    Explain,
    Grid,
  },
})
export default class Networks extends Vue {}
